<template>
  <span class="form__filename-icon-wrapper">
    <img v-if="!hideImage && file.isImage && !hasImageError" class="form__filename-icon-image" @load="onload" @error="onerror" :src="file.imageData" alt="">
    <span v-else-if="!hideImage && hasIcon" class="fiv-sqo" :class="`fiv-icon-${fileExtension}`"></span>
    <img v-else class="form__filename-icon" src="@/assets/images/attachment.svg" alt="attachment">
  </span>
</template>

<script>
import catalog from 'file-icon-vectors/dist/icons/square-o/catalog.json'

  export default {
    name: 'FileIcon',
    data() {
      return {
        hasImageError: false
      }
    },
    props: {
      file: {
        file: File,
        isImage: Boolean,
        imageData: String,
      },
      hideImage: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      filename() {
        if (!this.file || !this.file.file) return ''
        return this.file.file.name || this.file.file.metadata && this.file.file.metadata.name || ''
      },
      fileExtension() {
        const ext = this.filename.split('.').pop()
        return ext && ext.toLowerCase()
      },
      hasIcon() {
        return catalog.includes(this.fileExtension)
      }
    },
    methods: {
      onload(e) {
        console.log('onload', e)
      },
      onerror(e) {
        console.log('onerror', e)
        this.hasImageError = true
      },
    }
  }
</script>